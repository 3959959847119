import React from "react"
import './Style/notFoundStyle.css'
export const PageNotFound=()=>{
    return(
        <div className="all">
            <div className="all1">

            <div className="a404">
                404
            </div>
            <div className="pnf">
                Page Not Found
            </div>
            </div>

        </div>
    )
}
